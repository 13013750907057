<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row style="max-width: 450px">
      <v-col cols="12" md="12">
        <v-slide-y-transition appear>
          <!-- Request Password Reset Card -->
          <v-card 
            class="pa-3 pa-md-5 mx-auto" 
            :light="$vuetify.theme.dark ? false : true" 
          >
            <!-- Logo row-->
            <v-row>
              <v-img
                class="mx-auto mt-7 mb-8"
                max-width="100%"
                :src="'/assets/branding/' + (($vuetify.theme.dark) ? 'poweredByDoohDark.png' : 'poweredByDoohLight.png')" 
              ></v-img>
            </v-row>

            <!-- Back button -->
            <v-row style="position: absolute; top: 5px; left: 1px;">
              <v-col cols="12" class="my-0 pl-4 pa-0 text-left">
                  <v-btn
                    fab
                    color="primary"
                    small
                    @click="$router.push({ name: 'Login' })"
                  ><v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <div class="text-center">
                  <v-text-field
                    color="primary"
                    label="Your Email"
                    prepend-icon="mdi-email"
                    type="email"
                    v-model="username"
                  />
                  <v-btn 
                    depressed 
                    color="primary"
                    @click="resetPassword"
                    class="mt-4"
                  >Send Reset Email</v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="resetOutcome !== null">
              <v-col cols="12" class='text-center'>
                <span v-bind:style= "[resetError === true ? {color:'#ff5252'} : {color:'#04B526'}]">{{ resetOutcome }}</span>
              </v-col>
            </v-row>
          </v-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import AuthenticationController from "@/services/controllers/Authentication";

export default {
  methods: {
    resetPassword() {
      this.resetOutcome = null;
      AuthenticationController.passwordResetRequest({
        username: this.username,
      })
        .then((res) => {
          this.resetOutcome = "Reset email sent.";
          this.resetError = false;
          this.username = null;
        })
        .catch((err) => {
          this.resetOutcome = ''+err.response.data.message;
          this.resetError = true;
          this.$root.$emit("snackbarError", ''+err.response.data.message);
        });
    },
  },
  data: () => ({
    username: null,
    resetOutcome: null,
    resetError:false,
  }),
};
</script>